// ... your code that runs before foundation initiation

// Fix for foundation stylesheets being picked up as "null" or "not an object",
// implementation from here: http://foundation.zurb.com/forum/posts/3189-foundation-5-orbit-slider-ie8-issue
(function($) {
    'use strict';

    if (!Foundation.stylesheet) {
        Foundation._style_element = $('<style></style>').appendTo('head')[0];
        Foundation.stylesheet = Foundation._style_element.styleSheet;

        if (Foundation.stylesheet) {
            Foundation.stylesheet.cssRules = {
                length: 0
            };

            Foundation.stylesheet.insertRule = function(rule, index) {
                var media;
                var mediaMatch;
                var mediaRegex;
                var namespace;
                var ruleMatch;
                var ruleRegex;
                mediaRegex = /^\s*@media\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
                mediaMatch = mediaRegex.exec(rule);
                media = '';

                if (mediaMatch) {
                    media = '@media ' + mediaMatch[1] + ' ';
                    rule = mediaMatch[2];
                }

                ruleRegex = /^\s*(.*?)\s*\{\s*(.*?)\s*\}\s*$/;
                ruleMatch = ruleRegex.exec(rule);
                namespace = '' + media + ruleMatch[1];
                rule = ruleMatch[2];

                return this.addRule(namespace, rule);
            };
        } else if (window.console) {
            console.log('Could not fix Foundation CSS rules...');
        }
    }
})(jQuery);

// Accessible image sprites (modificat):
// https://www.paciellogroup.com/blog/2012/08/notes-on-accessible-css-image-sprites/

function HCTest() {
    'use strict';

    var objDiv;
    var strColor;
    var objFlag;

    // reference to img element used to check if images are disabled
    objFlag = document.getElementById('flag');
    if (objFlag === null) {
        return;
    }

    // Create a test div
    objDiv = document.createElement('div');

    // Set its color style to something unusual
    objDiv.style.color = 'rgb(31,41,59)';

    // Attach to body so we can inspect it
    document.body.appendChild(objDiv);

    // Use standard means if available, otherwise use the IE methods

    strColor = document.defaultView ? document.defaultView.getComputedStyle(objDiv, null).color : objDiv.currentStyle.color;

    // get rid of extra spaces in result
    // strColor = strColor.replace(/ /g,"");

    // Delete the test DIV

    document.body.removeChild(objDiv);

    // Check if we got back what we set (strColor== ??) If not we are in high contrast mode
    // Use .offsetwidth and .readyState (for IE) to check if images are enabled
    // If either images are disabled or high contrast is enabled (or both) the CSS stylesheet link will not be added to the page and the visually hidden text will be displayed in place of the missing background image

    if (!(((objFlag.offsetWidth === 1 && objFlag.readyState === 'complete') || (objFlag.offsetWidth === 1 && objFlag.readyState === undefined)) && (strColor === 'rgb(31,41,59)' || strColor === 'rgb(31, 41, 59)'))) {

        var objHead = document.getElementsByTagName('head');
        var objCSS = objHead[0].appendChild(document.createElement('link'));
        objCSS.rel = 'stylesheet';
        objCSS.href = '/media/css/alt.css';
        // objCSS.type = 'text/css';
    }
}

// Safely initiate foundation now
$(document).foundation();

// the rest of your code

$(window).load(function() {
    'use strict';

    // accessible image sprites
    HCTest();
});

// mobile-nav
(function() {

    //var mqm = window.matchMedia('(max-width: 46.25em)');
    var mqm = window.matchMedia('(max-width: 950px)');

    // get the button and menu nodes
    var button = document.querySelector('[aria-label="site"] button');
    var menu = button.nextElementSibling;

    // set initial (closed menu) states
    button.setAttribute('aria-expanded', 'false');
    button.hidden = false;
    if (mqm.matches) {
        menu.hidden = true;
    }

    $( window ).resize(function() {
        if (window.matchMedia('(min-width: 950px)').matches) {
            menu.hidden = false;
        }
    });

    button.addEventListener('click', function() {
        // toggle menu visibility
        var expanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded', String(!expanded));
        menu.hidden = expanded;
    });

})();

$(document).ready(function() {
    'use strict';

    // feature.js
    if (feature.svg) {
        $('html').addClass('svg');
    }

    // hamburger
    var $hamburger = $(".hamburger");
    $hamburger.on("click", function(e) {
      $hamburger.toggleClass("is-active");
      $('.menu').toggleClass("js-open");
      $('body').toggleClass("js-menu-open");
    });

    // Match Height
    $('.media-item').matchHeight();
    $('.proj-body').matchHeight();


    // smooth scroll top
    $(window).scroll(function() {
      if ($(this).scrollTop() > 400) {
          $(".top").fadeIn();
      } else {
          $(".top").fadeOut();
      }
    });
    $(function() {
      $('a[href*="#"]:not([href="#"],[href="#s"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 1000);
            return false;
          }
        }
      });
    });

    //Dropdowns
    $('.trigger').click(function() {
        if ($(this).next().hasClass('s-hidden')) {
            $('.drop').removeClass('s-visible').addClass('s-hidden');
            $(this).next().removeClass('s-hidden').addClass('s-visible');
            return false;
        } else
        if ($(this).next().hasClass('s-visible')) {
            $(this).next().removeClass('s-visible').addClass('s-hidden');
            return false;
        }
    });
    $('html').click(function() {
        if ($('.drop').hasClass('s-visible')) {
            $('.drop').removeClass('s-visible').addClass('s-hidden');
        }
    });
    $('.drop').click(function(event){
        event.stopPropagation();
    });

    // canvi svg per png
    if (!feature.svg) {
        var imgs = document.getElementsByTagName('img');
        var endsWithDotSvg = /.*\.svg$/;
        var i = 0;
        var l = imgs.length;
        for (; i !== l; ++i) {
            if (imgs[i].src.match(endsWithDotSvg)) {
                imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
            }
        }
    }

    /*
    //versió 1
    var cistella2 = $('form.cistella2');
    if (cistella2.length > 0) {
        cistella2.find('.buttonComanda').hide();
        var others = cistella2.find('option[value="others_field"]');
        others.each(function(){
            $(this).closest('div').find('select').on('change', function(){
                if ($(this).closest('div').find('select').val() == 'others_field') {
                    $('#paisModal').foundation('reveal', 'open');
                    cistella2.find('.pagamentOp').hide();
                    cistella2.find('.buttonTpv').hide();
                    cistella2.find('.buttonComanda').show();
                } else {
                    cistella2.find('.pagamentOp').show();
                    cistella2.find('.buttonComanda').hide();
                    cistella2.find('.buttonTpv').show();
                }
            });
        });
    }

    var cistella = $('form.cistella');
    if (cistella.length > 0) {
        var input = cistella.find('input[type="number"]');
        input.bind('change', function(event){
            event.preventDefault();
            var quantitat = parseFloat(input.val().replace(',', '.'));
            var enviament1 = parseFloat(input.data('enviament1').replace(',', '.'));
            var enviament2 = parseFloat(input.data('enviament2').replace(',', '.'));
            var canvi = parseInt(input.data('canvi'));
            var preu = parseFloat(input.data('preu').replace(',', '.'));
            var enviament = enviament1;
            if (quantitat >= canvi) {
                enviament = enviament2;
            }

            cistella.find('#preu').text((preu * quantitat).toFixed(2));
            cistella.find('#enviament').text(enviament.toFixed(2));
            cistella.find('#import').text(((preu * quantitat) + enviament).toFixed(2));
            return false;
        });
    }
    */

    var cistella2 = $('form.cistella2');
    if (cistella2.length > 0) {
        cistella2.find('.buttonComanda').hide();
        var others = cistella2.find('option[value="others_field"]');
        others.each(function(){
            $(this).closest('div').find('select').on('change', function(){

                if ($(this).closest('div').find('select').val() == 'others_field') {
                    $('#paisModal').foundation('reveal', 'open');
                    cistella2.find('.pagamentOp').hide();
                    cistella2.find('.buttonTpv').hide();
                    cistella2.find('.buttonComanda').show();

                    $('#57,#55,#82,#83,#69,#70').val('');
                } else {
                    cistella2.find('.pagamentOp').show();
                    cistella2.find('.buttonComanda').hide();
                    cistella2.find('.buttonTpv').show();

                    if (cistella2.find('#enviament').length > 0) {
                        var enviament = parseFloat(cistella2.find('#enviament').data('default').replace(',', '.'));
                        var enviamentEs = parseFloat(cistella2.find('#enviament').data('es').replace(',', '.'));
                        var import = parseFloat(cistella2.find('#import').data('default').replace(',', '.'));
                        var importEs = parseFloat(cistella2.find('#import').data('es').replace(',', '.'));
                        if ($(this).closest('div').find('select').val().startsWith('ES_')) {
                            import = importEs;
                            enviament = enviamentEs;
                        }

                        cistella2.find('#enviament').text(enviament.toFixed(2));
                        cistella2.find('#import').text((import).toFixed(2));
                        $('input[name="57"],input[name="82"],input[name="69"]').val(enviament.toFixed(2));
                        $('input[name="55"],input[name="83"],input[name="70"]').val(import.toFixed(2));
                    }
                }
            });
        });
    }

    var cistella = $('form.cistella');
    if (cistella.length > 0) {
        var input = cistella.find('input[type="number"]');
        input.bind('change', function(event){
            event.preventDefault();
            var quantitat = parseFloat(input.val().replace(',', '.'));
            var preu = parseFloat(input.data('preu').replace(',', '.'));
            cistella.find('#preu').text((preu * quantitat).toFixed(2));
            cistella.find('#import').text((preu * quantitat).toFixed(2));
            return false;
        });
    }


    $('#redsys_form').submit();
});
